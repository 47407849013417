<template>
    <div style="background-color: white; color: black;" class="px-5 mx-5">
        <h3>
            MotoGP – The electric era is GO! | Facebook
        </h3>
        <h6>
            by E2U | Jul 16, 2019 | Energica
        </h6>

        <hr>

        <div class="pb-3">
            <img src="./header.png">
        </div>

        <div class="centered-content">

            <iframe 
                src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2FMotoGP%2Fvideos%2F486823755425186%2F&show_text=false&width=476&t=0" 
                width="476" height="476" style="border:none;overflow:hidden" 
                scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true">
    
            </iframe>
            <a href="https://www.facebook.com/MotoGP/videos/486823755425186?s=1339250492&amp;v=e&amp;sfns=mo" target="_blank" rel="noopener noreferrer">MotoGP&nbsp;is at&nbsp;Sachsenring</a>
            <a href="https://l.facebook.com/l.php?u=http%3A%2F%2Fwww.rallystar.net%2F&amp;h=AT3KHZb9n_yEJ9NX12vV2J7x4uZiA8WTRpoedeMHJ2DDzUI0j3NGgHE0XiGZQLfJlL0p3U0LOr_fjoSEqf6HeLs6j3GKZmVX__lR15RSHrAooxPj7xyryv2TvS1MUBYXqV0VI_xXANpKDS1YbKE6vP5b-TxMWwl1oz4r" target="_blank" rel="nofollow noopener noreferrer" data-ft="{&quot;tn&quot;:&quot;-U&quot;}" data-lynx-mode="asynclazy">www.rallystar.net</a>
        </div>

    </div>
</template>