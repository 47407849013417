<template>
    <div style="padding:56.25% 0 0 0;position:relative;">
        <iframe src="https://player.vimeo.com/video/337490895?h=050ded3d44&portrait=0" 
                style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
        </iframe>
    </div>

    <section id="bike-info">
        <div id="tech">
            <a href="#technology" 
                class="centered-content"
                data-bs-toggle="collapse"  role="button" aria-expanded="false" aria-controls="technology" >
                <h3>Technology <li class="material-icons">add_circle</li></h3>
            </a>
            <div class="collapse" id="technology">
                <p>Energica motorcycles are the ultimate expression of Italian exclusivity, masterfully 
                    manufactured in the Italian Motor Valley in Modena, and now available for the 
                    discerning South African market. Energica benefits in countless and immeasurable 
                    ways from the close relationship and consultation with parent company CRP Group. 
                    The overall culture that distinguishes CRP Group is fully integrated into Energica Motor Company.
                </p>
                <p>
                    Diving in F1 world and aerospace industries keeps Energica at the forefront of 
                    state-of-the-art technology and ready for future innovation.
                </p>
            </div>
        </div>
        <div id="Performance">
            <a href="#performance" 
                class="centered-content"
                data-bs-toggle="collapse"  role="button" aria-expanded="false" aria-controls="performance" >
                <h3>Performance <li class="material-icons">add_circle</li></h3>
            </a>
            <div class="collapse" id="performance">
                <p>
                    The green heart of Energica motorcycles is a synchronous oil-cooled motor with permanent magnets. 
                    The excitement of riding an electric motorcycle is more than just power. With no transmission gears, 
                    clutch, or shifting, the process of searching for the “right gear” and RPM is eliminated, 
                    allowing for full concentration on acceleration, full throttle control, cornering and braking.
                </p>
                <p>
                    The technology is more intuitive and gives the feeling of torque to the motor instantly. 
                    Energica is taking electric performance to the next level!
                </p>
            </div>
        </div>
        <div id="Power">
            <a href="#power" 
                class="centered-content"
                data-bs-toggle="collapse"  role="button" aria-expanded="false" aria-controls="power" >
                <h3>Power <li class="material-icons">add_circle</li></h3>
            </a>
            <div class="collapse" id="power">
                <p>
                    Energica motorcycles use a high-energy lithium polymer (Li-NMC) battery.
                </p>
                <p>
                    Contained in sealed housing holding battery cells, the Battery Management System (BMS) 
                    has all the necessary provisions to ensure the safety of the vehicle. The battery keeps 
                    all high-voltage components encapsulated, making it unlikely to accidentally be exposed 
                    to risk. The battery pack is an intelligent device with its own electrical brain, 
                    comprising dozens of sensors and electromechanical devices that constitute a closed 
                    subsystem to ensure maximum vehicle performance and driver safety in all environmental conditions.
                </p>
                <p>
                    Energica has designed a cooling system for the battery pack that is superior thanks to its 
                    specific ventilation paths that limit stress on the batteries. This provides considerable 
                    benefits to both performance of the vehicle and the life of the battery.
                </p>
                <p>
                    Energica is the only company in the world that has designed, patented and adopted this 
                    type of technology on its own bikes.
                </p>
            </div>
        </div>
        <div id="Control">
            <a href="#control" 
                class="centered-content"
                data-bs-toggle="collapse"  role="button" aria-expanded="false" aria-controls="control" >
                <h3>Control <li class="material-icons">add_circle</li></h3>
            </a>
            <div class="collapse" id="control">
                <p>
                    The brain of Energica motorcycles is the sophisticated Vehicle Control Unit (VCU). 
                    Unlike other vehicles where the control units work separately on all controllers, 
                    the Energica’s battery, inverter, charger and ABS are constantly monitored and managed 
                    by a technological jewel: the VCU, completely designed and developed by Energica.
                </p>
                <p>
                    The state-of-the-art VCU implements a multi-map adaptive energy, and a power management 
                    algorithm manages the vehicle, carefully monitoring and adjusting the motor’s power 
                    according to the throttle thrust 100 times per second while riding.
                </p>
                <p>
                    The VCU interfaces with the ABS system, controlling 
                    <a href="https://en.wikipedia.org/wiki/Regenerative_brake" target="_blank" class="text-primary" style="text-decoration: underline;">regenerative engine braking</a>. 
                    This ensures the highest efficiency in energy usage and full functionality of the 
                    battery throughout the life of the vehicle, but also delivers great drive-ability 
                    and experience. The system is based on an architecture with dual redundant 
                    microprocessors to ensure the highest safety standards that constantly monitor 
                    the status of the battery, even in the key-off position.
                </p>
                <p>
                    Energica is the only company in the world that has designed, patented and adopted this 
                    type of technology on its own bikes.
                </p>
            </div>
        </div>
        <div id="Ride-by-Wire">
            <a href="#ride-by-wire" 
                class="centered-content"
                data-bs-toggle="collapse"  role="button" aria-expanded="false" aria-controls="ride-by-wire" >
                <h3>Ride by Wire
                    <li class="material-icons">add_circle</li></h3>
            </a>
            <div class="collapse" id="ride-by-wire">
                <p>
                    The ride-by-wire system is the direct interface between the driver and the vehicle. 
                    Understanding its significance, Energica paid special attention to the development 
                    and refinement of this device. The throttle control is composed of a rotary 
                    potentiometer and a safety micro-switch. The potentiometer detects the rotation 
                    of the throttle and is activated every time the accelerator is engaged or released. 
                    The VCU manages both the potentiometer and the switch signal to fine-tune the torque 
                    demand to the engine based on the mapping selected, while the micro-switch is used 
                    as a safety feature in case of malfunction of the throttle control.
                </p>
                <p>
                    The driving experience corresponds to four different motor mappings, which correspond 
                    to different values of maximum torque and different throttle responses. In addition, 
                    there are three different settings of regenerative braking that can be manually 
                    turned off by the driver.
                </p>

            </div>
        </div>
        <div id="Park Assist">
            <a href="#park-assist" 
                class="centered-content"
                data-bs-toggle="collapse"  role="button" aria-expanded="false" aria-controls="park-assist" >
                <h3>Park Assist <li class="material-icons">add_circle</li></h3>
            </a>
            <div class="collapse" id="park-assist">
                <p>
                    Energica motorcycles are equipped with a “PARK ASSISTANT” in order to facilitate parking 
                    and maneuvers on slopes or in rush traffic.
                </p>
                <p>
                    The VCU limits both the speed and the torque of the vehicle. The PARK ASSISTANT works 
                    back and forth (reverse and forward mode) with maximum speed 1.74 miles/hour (mph) and 
                    maximum torque 50Nm. Push “START” for 2 seconds to enter the “PARK ASSISTANT” mode. 
                    Further quick push of “START” button, will switch from forward to reverse mode to 
                    assist in tough maneuvers and if stuck in the middle of traffic. Pushing the “START” 
                    button for at least 1 second when the motorcycle is still will get it back to normal mode.
                </p>
            </div>
        </div>
        <div id="Frame">
            <a href="#frame" 
                class="centered-content"
                data-bs-toggle="collapse"  role="button" aria-expanded="false" aria-controls="frame" >
                <h3>Frame <li class="material-icons">add_circle</li></h3>
            </a>
            <div class="collapse" id="frame">
                <p>
                    Energica motorcycles have an Italian-style, tubular, craftsman-like trellis high-strength 
                    steel frame. The steel frame provides a better feel than aluminum.
                </p>
                <p>
                    For years technicians believed that aluminum-framed road race motorcycles provided more 
                    “drive” than their steel counterparts. However, research has discovered that the steel 
                    frames actually act similarly to a spring, absorbing some energy as they flex and releasing 
                    it again when they snap back to their original form.
                </p>
            </div>
        </div>
        <div id="Dashboard">
            <a href="#dashboard" 
                class="centered-content"
                data-bs-toggle="collapse"  role="button" aria-expanded="false" aria-controls="dashboard" >
                <h3>Dashboard <li class="material-icons">add_circle</li></h3>
            </a>
            <div class="collapse" id="dashboard">
                <p>
                    The TFT full-color LCD dashboard on Energica motorcycles with 16.7 million display colors has excellent visibility.
                </p>
                <p>
                    The active matrix dashboard creates a real Human Machine Interface for the motorcycles.
                </p>
                <p>
                    This technology allows great flexibility, supporting the flow of information and interactivity 
                    with the vehicle system. It can provide an extensive menu of configurations and advanced user 
                    diagnostics, which are easy to read. It also monitors all of the functions and phases of 
                    vehicle operation, such as charging, driving, standby, and the connection with the outside world.
                </p>
            </div>
        </div>
        <div id="ABS">
            <a href="#abs" 
                class="centered-content"
                data-bs-toggle="collapse"  role="button" aria-expanded="false" aria-controls="abs" >
                <h3>ABS <li class="material-icons">add_circle</li></h3>
            </a>
            <div class="collapse" id="abs">
                <p>
                    The latest generation ABS BOSCH on Energica motorcycles not only prevents the wheels from 
                    locking but is equipped with a rear-wheel lift-up mitigation system that prevents overturn 
                    during emergency braking.
                </p>
                <p>
                    The driver has the ability to turn the ABS system on and off using the dashboard.
                </p>
            </div>
        </div>
        <div id="eABS">
            <a href="#eabs" 
                class="centered-content"
                data-bs-toggle="collapse"  role="button" aria-expanded="false" aria-controls="eabs" >
                <h3>eABS <li class="material-icons">add_circle</li></h3>
            </a>
            <div class="collapse" id="eabs">
                <p>
                    The latest generation eABS mounted on the Energica motorcycle is able to limit the 
                    maximum regenerative torque in case of slippery conditions.
                </p>
                <p>
                    In particular, the system verifies the presence of friction conditions and, in case 
                    of slippery conditions, limits the maximum regeneration torque.
                </p>
            </div>
        </div>
    </section>

    <div id="carouselBikes" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="./Ego-2020.png" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="./Eva-2020.png" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="./Eva-SS9-2020.png" class="d-block w-100" alt="...">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselBikes" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselBikes" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
</template>

<style scoped>
    li {
        vertical-align: middle;
    }

    a {
        text-decoration: none; 
        color: white;
    }

    a:hover {
        color: white;
        opacity: 0.9;
    }
</style>