<template>
    <div style="background-color: white; color: black;" class="px-5 mx-5">
        <h3>
            First Energica Review South Africa – The Bike Show
        </h3>
        <h6>
            by Gerhard | Jun 6, 2020 | Energica
        </h6>

        <hr>

        <div class="pb-3">
            <img src="./header.png">
        </div>

        <div>
            <p>Thank you – Donovan Fourie (The Bike Show) and Rob Portman (RideFast Magazine)!</p>
            <p>
                “The Energica Italian electric sportbikes, the same brand behind the MotoE racing 
                series, have landed on our shores, and Donovan and Rob took the Ego and the Eva 
                to the Cradle Road to find out what the big deal is.”
            </p>
            <p>Source: <a href="https://youtu.be/9tG2VWtc5ZE" target="_blank" rel="noopener noreferrer">Energica Electric Sportbikes Review – The Bike Show</a></p>
        </div>

        <div style="padding:56.25% 0 0 0;position:relative;">
            <iframe src="https://player.vimeo.com/video/426445632?h=e275d47223&portrait=0" 
                style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
            </iframe>
        </div>

    </div>
</template>