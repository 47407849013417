<template>
    <div class="pt-3">
        <h2 style="text-align: center;">Configure Your Dream Right Here</h2>
        <p>All orders originating from the African Continent will be fulfilled by Electronia. We will ensure that your machine is thoroughly prepped and will provide you with training (at our offices in Vanderbijlpark, South Africa) to ensure that you fully understand the technological marvel you acquired.</p>
        <p>Click the Configurator image below to customize the model that will make your dream come true… Once you completed the configuration, please forward the email you will receive from Energica to us at <a href="mailto:sales@electronia.co.za" target="_blank" rel="noopener noreferrer">sales@electronia.co.za</a> so that we can get in touch with you asap to discuss next steps.</p>
    </div>

    <div id="need-to-know" class='row pt-3'>
        <div class='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-8 col-xxl-8 left-content'>
            <h3>What you Need to Know</h3>
            <h4>Order Lead Time – 55 Days (approx.)</h4>
            <ul>
            <li>Manufacture = 21 Days</li>
            <li>Shipping = 26 Days</li>
            <li>Customs Clearing = 5 Days</li>
            <li>Pre-Delivery Prepping = 3 Days</li>
            </ul>
            <h4>3 x Payment Options (at placement of order)</h4>
            <ol>
            <li>Full payment = R10,000.00 discount</li>
            <li>30% Deposit, 70% before shipping</li>
            <li>Financing</li>
            </ol>
            <h4>VAT &amp; Shipping</h4>
            <p>VAT, shipping, import duties and taxes will be added to your order.</p>
            <p><strong>NOTE</strong>: These are approximations and subject to change without notice.</p>
        </div>
        <div class='col-12 col-sm-12 col-md-10 col-lg-4 col-xl-4 col-xxl-4'>
            <div>
                <h2 style="text-align: center;">Configurator</h2>
                <p>Click the image below to configure your model (you will be redirected to the Energica Configurator page).</p>
                <p>Play around and have fun customising your baby (formal order will only be placed once you hit the BOOK button).</p>
            </div>
            <div class="centered-content">
                <a href="https://configurator.energicamotor.com/start" target="_blank">
                    <img decoding="async" width="100%" height="100%" 
                        src="./Configurator.png" 
                        title="Configurator" 
                        >
                </a>
            </div>
        </div>
    </div>

    <section id="detailed-specs">
        <h3 style="text-align: center;">Detailed Specifications</h3>
        <p>Click on a model to download a detailed spec sheet.</p>
        <img src="./adobe.png" style="height: 39px; width: 158px;">
        <div class='row py-3'>
            <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
                <a href="./bikes/Detail-Specs-EGO.pdf" download="Detail-Specs-EGO.pdf"><img style="border-radius: 15px;" src="./Model-Ego.png"></a>
            </div>
            <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
                <a href="./bikes/Detail-Specs-EVA-ES9.pdf" download="Detail-Specs-EVA-ES9.pdf"><img style="border-radius: 15px;" src="./EvaEsseEsse.png"></a>
            </div>
            <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
                <a href="./bikes/Detail-Specs-EVA-Ribelle.pdf" download="Detail-Specs-EVA-Ribelle.pdf"><img style="border-radius: 15px;" src="./Eva.png"></a>
            </div>
        </div>

        <h3 style="text-align: center;">General Documentation</h3>
        
        <img src="./adobe.png" style="height: 39px; width: 158px;">

        <ul class="pt-3">
            <li>Statement by Mr. Livia Cevolini, CEO Energica Motor Company - <a href="./specs/PR-Eicma-2019-MY-2020.pdf" download="PR-Eicma-2019-MY-2020.pdf">PR Eicma 2019 – MY 2020</a></li>
            <li>Specification Brochure - <a href="./specs/web-catalogue_techsheet_2020.pdf" download="web-catalogue_techsheet_2020.pdf">Web Catalogue Techsheet_2020</a></li>
            <li>What will happen when your bike arrives - <a href="./specs/Delivery-Instruction.pdf" download="Delivery-Instruction.pdf">Delivery Instruction</a></li>
            <li>Maintenance schedule - <a href="./specs/10.000km-maintenance-interval-checklist.pdf" download="10.000km-maintenance-interval-checklist.pdf">10.000km Maintenance Interval Checklist</a></li>
        </ul>
    </section>
</template>