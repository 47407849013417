<template>
    <div id="footer-info" class="footer mt-4">
        <div style="background-color: rgb(23, 23, 23);" class="centered-content">
            <div class="px-2 mx-2 d-flex">
                © Copyright  
                <a href="https://e2u.co.za" target="_blank" rel="noopener"><strong>Electronia (Pty) Ltd</strong></a>
                2017 - 2022  | Built by 
                <a href="https://gjvr.net" target="_blank" rel="noopener"><strong>Gerhard</strong></a> 
                | Hosted by 
                <a href="https://erpa.co.za/SiteGround" target="_blank" rel="noopener"><strong>SiteGround</strong></a>
            </div>
            <div class="rounded d-flex" >
                <a href="https://t.me/EnerItal" 
                    style="background-color: rgb(56, 156, 233); border-radius: 25px; color:white; width: 40px; height: 40px;" 
                    class="p-2 d-flex centered-content" 
                    target="_self">
                    <img src="./icons8-telegram-app-100.png">
                </a>
                <a class="" href="https://twitter.com/ElectroniaL">
                    <img style="width: 40px; height: 40px;" src="./icons8-twitter-circled-100.png">
                </a>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .rounded {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    .footer {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background-color: rgb(34, 34, 34);
        color: #666;
    }

    a {
        text-decoration: none;
        color: rgb(102, 102, 102);
        margin: 0 5px 0 5px;
    }
</style>