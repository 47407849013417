<template>
    <div style="background-color: white; color: black;" class="px-5 mx-5">
        <h3>Interview with Rodger – DSTV Audio channel 869</h3>
        <h6>by Gerhard | Dec 18, 2020 | Energica</h6>

        <hr>

        <div>
            <img src="./header.jpeg">
        </div>

        <div>
            <p>Thank you, Rodger!</p>
            <p>“The Energica Italian electric motorcycles, the same brand behind the MotoE racing 
                series, now distributed in South Africa by Electronia”
            </p>
        </div>
        <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" 
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/949810615&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
        </iframe>
        <div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;">
            <a href="https://soundcloud.com/user-559806184-751052001" title="Umca" target="_blank" style="color: #cccccc; text-decoration: none;">
                Umca
            </a> · 
            <a href="https://soundcloud.com/user-559806184-751052001/interview-with-rodger" 
                title="Interview with Rodger" target="_blank" style="color: #cccccc; text-decoration: none;">
                Interview with Rodger
            </a>
        </div>

    </div>
</template>