<template>
    <div id="contact-details" class='row'>
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
            <table class="table table-bordered table-responsive text-white w-100" >
                <tbody>
                    <tr class="row-1">
                        <td colspan="2" class="column-1"><h1>Address</h1></td>
                    </tr>
                    <tr class="row-2">
                        <td class="column-1">
                            <strong>Physical</strong>
                        </td>
                        <td class="column-2">
                            Shop 31 Riverside Shopping Centre<br>
                            C/o Frikkie Meyer &amp; Hendrick van Eck Boulevards<br>
                            Vanderbijlpark 1911<br>
                            Gauteng Province<br>
                            South Africa
                        </td>
                    </tr>
                    <tr class="row-3">
                        <td class="column-1"><strong>Postal</strong>
                        </td>
                        <td class="column-2">
                            PO Box 5593<br>
                            Vanderbijlpark 1900<br>
                            Gauteng Province<br>
                            South Africa
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
            <table class="table table-bordered table-responsive text-white w-100">
                <tbody>
                    <tr class="row-1">
                        <td colspan="2" class="column-1"><h1>Contact</h1></td>
                    </tr>
                    <tr class="row-2">
                        <td class="column-1"><strong>Office</strong></td><td class="column-2">+27 83 415 3333</td>
                    </tr>
                    <tr class="row-3">
                        <td class="column-1"><strong>Mobile</strong></td><td class="column-2">+27 83 415 3333</td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-bordered table-responsive text-white w-100">
                <tbody>
                <tr class="row-1">
                    <td colspan="2" class="column-1"><h1>Business Hours</h1></td>
                </tr>
                <tr class="row-2">
                    <td class="column-1"><strong>Weekday</strong></td><td class="column-2">09:00 to 17:00</td>
                </tr>
                <tr class="row-3">
                    <td class="column-1"><strong>Saturday</strong></td><td class="column-2">09:00 to 13:00</td>
                </tr>
                <tr class="row-4">
                    <td class="column-1"><strong>Sunday</strong></td><td class="column-2">Closed</td>
                </tr>
                </tbody>
                </table>
        </div>
    </div>

    <div id="location" class="py-4">
        <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d222.70641735905477!2d27.841128545278185!3d-26.73471559044098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e94597c0a1f482d%3A0x2029b35ca7b64049!2sElectronia!5e0!3m2!1sen!2sus!4v1671637498160!5m2!1sen!2sus" 
            width="100%" height="450" style="border:0; border-radius: 25px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
            >
        </iframe>
    </div>

    <form id="contact-us" @submit.prevent="submit">
        <h1>Get in Touch</h1>
        <div class='row mb-3'>
            <div class='col-6'>
                <input type="text" id="name" class="form-control" v-model="contact.name" placeholder="Name" required>
            </div>
            <div class='col-6'>
                <input type="email" id="email" class="form-control" v-model="contact.email" placeholder="Email Address" required>
            </div>
        </div>
        <textarea type="text" id="message" class="form-control" v-model="contact.message" placeholder="Message" rows="8" required></textarea>
        <div class="d-flex pt-3">
            <button class="btn btn-primary ms-auto">Submit</button>
        </div>
    </form>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { useRouter, useRoute } from 'vue-router'
let router = useRouter()
let route = useRoute()
const toast = useToast()
const store = useStore()

let contact = ref({
    name: '',
    email: '',
    message: '',
})
</script>