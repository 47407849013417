<template>
    <div class='row pt-4' style="color: black !important;">
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
            <div class="card card-body">
                <img src="./Billys-Bikes-400x250.png">
                <div class="m-2 p-2">
                    <h5>Energica Ego – Billy’s Bikes</h5>
                    <h6>by Gerhard | Dec 23, 2020</h6>
                    <p>Thank you, Billy! "During the MotoGP calendar there’s also the FIM MotoE series, 
                        obviously for electric bikes only, well Energica ones to be more precise. 
                        They’ve been involved since 2019 with the electric bike new class, 
                        which is gaining momentum at an unrivaled...
                    </p>
                    <router-link :to="{name: 'energicaegobllysbikes'}">read more</router-link>
                </div>
            </div>
        </div>
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
            <div class="card card-body">
                <img src="./Energica-400x250.jpeg">
                <div class="m-2 p-2">
                    <h5>Interview with Rodger – DSTV Audio channel 869</h5>
                    <h6>by Gerhard | Dec 18, 2020 </h6>
                    <p>Thank you, Rodger! "The Energica Italian electric motorcycles, 
                        the same brand behind the MotoE racing series, now distributed 
                        in South Africa by Electronia" <a href="https://soundcloud.com/user-559806184-751052001/interview-with-rodger">interview-with-rodger</a>
                    </p>
                    <router-link :to="{name: 'interviewwithrodgerdstvaudiochannel869'}">read more</router-link>
                </div>
            </div>
        </div>
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
            <div class="card card-body">
                <img src="./The_Bike_Show_Review_2020-06-06_0445-400x250.png">
                <div class="m-2 p-2">
                    <h5>First Energica Review South Africa – The Bike Show</h5>
                    <h6>by Gerhard | Jun 6, 2020 </h6>
                    <p>
                        Thank you - Donovan Fourie (The Bike Show) and Rob Portman (RideFast Magazine)! 
                        "The Energica Italian electric sportbikes, the same brand behind the MotoE 
                        racing series, have landed on our shores, and Donovan and Rob took the 
                        Ego and the Eva to the Cradle Road to find...
                    </p>
                    <router-link :to="{name: 'firstenergicareviewsouthafricathebikeshow'}">read more</router-link>
                </div>
            </div>
        </div>
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
            <div class="card card-body">
                <img src="./2019-07-15_1640-400x250.png">
                <div class="m-2 p-2">
                    <h5>MotoGP – The electric era is GO! | Facebook</h5>
                    <h6>by E2U | Jul 16, 2019 </h6>
                    <p>
                        Video Source: MotoGP is at Sachsenring.After The First MotoE Race, 
                        It’s Not The Present Of MotoE That Really Matters – It’s The 
                        FutureSunday’s historic electric first MotoE race at a grand 
                        prix event was only the very beginning of EV motorcycle...
                    </p>
                    <router-link :to="{name: 'motogptheelectriceraisgofacebook'}">read more</router-link>
                </div>
            </div>
        </div>
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
            <div class="card card-body">
                <img src="./2019-07-07_1145-400x250.png">
                <div class="m-2 p-2">
                    <h5>FIM MotoE™ World Cup | MotoGP™</h5>
                    <h6>by Gerhard | Jul 7, 2019 </h6>
                    <p>
                        Starting in 2019, the new MotoE™ World Cup will be a leap into the future 
                        through the competition of the world’s first Fast Charge electric motorcycles 
                        manufactured by Italian company Energica, bringing together performance 
                        and zero emissions at selected...
                    </p>
                    <router-link :to="{name: 'fimmotoeworldcupmotogp'}">read more</router-link>
                </div>
            </div>
        </div>
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
            <div class="card card-body">
                <img src="./2019-06-03_1101-400x250.png">
                <div class="m-2 p-2">
                    <h5>Discover the Energica Ego Corsa MotoE™ motorcycle | MotoGP™</h5>
                    <h6>by E2U | Jun 3, 2019 </h6>
                    <p>
                        Get to know the facts and figures about the impressive MotoE™ bike ahead of 2019 Source: 
                        Discover the Energica Ego Corsa MotoE™ motorcycle | MotoGP™
                    </p>
                    <router-link :to="{name: 'FIMEnelMotoEWorldCup'}">read more</router-link>
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
    img {
        border-radius: 15px;
    }

    .card {
        border-radius: 15px;
        margin: 0;
        padding: 0;
    }

    .col-12 {
        padding-bottom: 20px;
    }

     h5, h6, p {
        color: black;
     }
</style>