<template>
    <div style="background-color: white; color: black;" class="px-5 mx-5">
        <h3>Energica Ego – Billy’s Bikes</h3>
        <h6>by Gerhard | Dec 23, 2020</h6>

        <hr>

        <div>
            <img src="./header.png">
        </div>

        <div>
            <p>Thank you, Billy!</p>
            <p>“During the MotoGP calendar there’s also the FIM MotoE series, obviously for 
                electric bikes only, well Energica ones to be more precise. They’ve been 
                involved since 2019 with the electric bike new class, which is gaining momentum 
                at an unrivaled pace. Indeed they’ll continue as the single manufacturer 
                until 2022. All good to watch and be impressed by you say, but what if I 
                wanted to own one for the road and visits to my local pub, seeing as ‘race’ 
                bikes are for track use only?”
            </p>
            <p>“Well I have the answer and this is it; the stunning Energica Ego that is 
                basically their race bike for road use. Over 150hp and 200Nm +/- is what 
                you get and every ride is as exciting as finding your first porn mag under 
                your dad’s bed. An obscene amount of thrust is delivered in such a civilised 
                fashion that I had no interest in anything with a petrol engine for the 
                week the Ego was in my garage. I wouldn’t say it’s the best bike I’ve ever 
                ridden, because that debate can be argued for many hours and is dependant 
                on what you’ve been drinking, but the Ego is, without doubt, one of the most impressive.”
            </p>
            <p>Source: <a href="http://billysbikes.co.za/new-rides/1900-energica-ego.html" target="_blank">Billy’s Bikes</a></p>

        </div>
        <iframe width="100%" height="600px" 
            src="https://www.youtube.com/embed/Hc9vBsrWf2k" title="YouTube video player" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
        </iframe>
    </div>
</template>