<template>
    <div style="background-color: white; color: black;" class="px-5 mx-5">
        <h3>
            Discover the Energica Ego Corsa MotoE™ motorcycle | MotoGP™
        </h3>    
        <h6>
            by E2U | Jun 3, 2019 | Energica
        </h6>

        <hr>

        <div class="pb-3">
            <img src="./header.png">
        </div>

        <a href="">Discover the Energica Ego Corsa MotoE™ motorcycle | MotoGP™</a>

    </div>
</template>