<template>
    <section id="intro">
        <h3>Gallery</h3>
        <p>This page showcases significant events and memorable moments. We are making history – Enjoy!</p>
    </section>

    <div id="initial" class='row'>
        <div class='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6'>
            <div>
                <h1>South Africa – The Eagle has Landed!</h1>
                <h3>The EGO and EVA is Here!!</h3>
                <p>Arrived in the midst of the COVID-19 lockdown, on <strong>13</strong> May 2020, 
                    at <strong>13</strong>:<strong>13</strong> (nah, we’re not superstitious…), 
                    at Shop 31 (not <strong>13</strong>, eish…), Riverside Boulevard Shopping Centre, 
                    <span>C/o Frikkie Meyer &amp; Hendrick van Eck Boulevards</span>, Vanderbijlpark.
                </p>
                <p>
                    Unboxing was enthusiastically attacked by Electronia MD, Kobus Janse van Rensburg, 
                    his son, Kris, daughter Lica (short for Angelique and photographer) and Francois 
                    van Vollenstee, Technical Manager – What fun!
                </p>
            </div>
            <div id="eagleCarousel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="./eagle_landed/1.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/2.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/3.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/4.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/5.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/6.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/7.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/8.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/9.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/10.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/11.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/12.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/13.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/14.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/15.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/16.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                  <div class="carousel-item">
                    <img src="./eagle_landed/17.jpg" class="d-block w-100" style="border-radius: 15px;">
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#eagleCarousel" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#eagleCarousel" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
        <div class='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6'>
            <div class="et_pb_text_inner">
                <h1>First Public Appearance!</h1>
                <p>
                    We hosted our first ever photo day, on&nbsp;Saturday, 16 May 2020, 
                    swiftly after unboxing, to Stonehenge in Africa river lodge, situated 
                    on the banks of the Vaal River just outside the small tourist town of 
                    Parys.&nbsp;This was the ideal venue to showcase the sheer beauty of 
                    what the Energica bike range has to offer.
                </p>
                <p>
                    The three gents depicted in the photos are; left, MD of Stonehenge in Africa, 
                    Stephen Le Roux, center, Mike Brucher (on the Beemer…) and on the right is 
                    Stonehenge Fasilitator, Jason Eugene Le Roux.
                </p>
            </div>
            <div id="firstCarousel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="./first/1.jpg" class="d-block w-100" style="border-radius: 15px;">
                      </div>
                      <div class="carousel-item">
                        <img src="./first/2.jpg" class="d-block w-100" style="border-radius: 15px;">
                      </div>
                      <div class="carousel-item">
                        <img src="./first/3.jpg" class="d-block w-100" style="border-radius: 15px;">
                      </div>
                      <div class="carousel-item">
                        <img src="./first/4.jpg" class="d-block w-100" style="border-radius: 15px;">
                      </div>
                      <div class="carousel-item">
                        <img src="./first/5.jpg" class="d-block w-100" style="border-radius: 15px;">
                      </div>
                      <div class="carousel-item">
                        <img src="./first/6.jpg" class="d-block w-100" style="border-radius: 15px;">
                      </div>
                      <div class="carousel-item">
                        <img src="./first/7.jpg" class="d-block w-100" style="border-radius: 15px;">
                      </div>
                      <div class="carousel-item">
                        <img src="./first/8.jpg" class="d-block w-100" style="border-radius: 15px;">
                      </div>
                      <div class="carousel-item">
                        <img src="./first/9.jpg" class="d-block w-100" style="border-radius: 15px;">
                      </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#firstCarousel" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#firstCarousel" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>

    <section id="videos">
        <div>
            <h1 style="text-align: center;">First Hands-On!</h1>
            <p style="text-align: center;">Donovan Fourie (The Bike Show) and Rob Portman (RideFast Magazine).</p>
            <h2 style="text-align: center;">Are We Having Fun Yet???</h2>
        </div>
        <div class='row'>
            <div class='col-6'>
                <div style="padding:56.6% 0 0 0;position:relative;">
                    <iframe src="https://player.vimeo.com/video/420945268?h=67ffb3a703&portrait=0" 
                        style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
                    </iframe>
                </div>
            </div>
            <div class='col-6'>
                <div style="padding:56.25% 0 0 0;position:relative;">
                    <iframe src="https://player.vimeo.com/video/420930990?h=c724a6887f&portrait=0" 
                        style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
                    </iframe>
                </div>
            </div>
        </div>
        <div class='row'>
            <div class='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6'>
                <img src="./videos/1.jpg" style="height: auto; width: 100%; border-radius: 25px;">

            </div>
            <div class='col-12 col-sm-12 col-md-10 col-lg-8 col-xl-6 col-xxl-6'>
                <img src="./videos/2.jpg" style="height: auto; width: 100%; border-radius: 25px;">
        
            </div>
        </div>
    </section>

    <section id="track-day" class="pt-3">
        <div>
            <h1>MyElectric Academy Track Day</h1>
            <p>The First Ever MyElectric Academy Track Day, hosted by Energica, and Electronia 
                was there! In a sweltering hot 47 degrees Celsius, we sweated it out with 
                colleagues from Sweden, Amsterdam and other exotic places.
            </p>
            <p>
                Thank you, Energica, for making this a truly stellar and memorable event. 
                The friendships forged will last a lifetime.
            </p>
        </div>
        <div id="trackCarousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="./track_day/1.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/2.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/3.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/4.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/5.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/6.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/7.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/8.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/9.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/10.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/11.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/12.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/13.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/14.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/15.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/16.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/17.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/18.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/19.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/20.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
              <div class="carousel-item">
                <img src="./track_day/21.jpg" class="d-block w-100" style="border-radius: 15px;">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#trackCarousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#trackCarousel" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
        </div>
    </section>
</template>