<template>
  <div class="pb-2">
    <img src="./cover.jpg">
  </div>

  <section id="general-vids">
    <div class='row'>
      <div class='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
        <div>
          <h1>Ride The Future — Join The Revolution</h1>
          <p>Internal combustion transportation has evolved for more than 100 years. 
            The electric transportation revolution (referred to as the next 
            <a href="https://en.wikipedia.org/wiki/Disruptive_innovation" target="_blank" rel="noopener noreferrer">Disruptive Innovation</a>
            ) is still in its infancy - Imagine where it will be 100 years from now! 
            Gas-guzzling anthropoids will be like the horse-and-buggy must have been to Henry Ford - Relics of an ancient past.
          </p>
          <p>Technological advancements in electric transportation is disrupting Big Oil, 
            held in place only by the sheer momentum of the massive internal combustion 
            transportation industry. But "the writing is on the wall"… Fossil-fuel is dying. 
            Long live Sustainable and Renewable energy!
          </p>
        </div>
    
      </div>
      <div class='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
        <iframe src="https://player.vimeo.com/video/337485772?h=38cec782ad" width="640" height="360" 
            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
        </iframe>
      </div>
    </div>

    <div class='row'>
      <div class='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
        <div class="et_pb_text_inner">
          <h1>Energica Expands the Universe of Riding</h1>
          <p>
            No emissions, no shifting, no clutch action, and maximum thrills. 
            Energica Motor Company is the first Italian manufacturer of high-performing 
            electric motorcycles. Our motorcycles are the ultimate expression of Italian 
            exclusivity, masterfully manufactured in the Italian Motor Valley in Modena. 
            Energica's secret formula is based on a simple realization, that 0% emissions 
            mean 100% fun. Bikes based on our racing experience, with superb components 
            and build quality, but made for the real world, for real roads and real riders.
          </p>
          <p>Don't miss out!</p>
        </div>
      </div>
      <div class='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
        <iframe src="https://player.vimeo.com/video/337490895?h=050ded3d44" width="640" height="360" 
            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
        </iframe>
      </div>
    </div>
  </section>

  <section id="elec-dna">
    <div>
      <h1>The DNA of Electronics</h1>
      <p>
        Any mechanical, electrical or biological system needs instructions to be able to work. 
        For biological organisms there is DNA, and for software there is the number of lines of program code.
      </p>
      <blockquote>
        <p>The Energica Software has reached 1.1M lines of code (645,000 lines only for the VCU), over 200,000 more than a Space Shuttle!</p>
      </blockquote>

      
    </div>
    <div>
      <p>Here is a list to compare this data:</p>
      <div class='row'>
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 centered-content round-corner'>
          <h3 class="pt-3">40,000</h3>
          <br>
          <h4>Space Shuttle</h4>
        </div>
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 centered-content round-corner' 
          style="background-color: rgb(32, 56, 10);">
          <h3 class="pt-3" style="color: rgb(92, 216, 0);">645,000</h3>
          <br>
          <h4>Energica VCU</h4>
        </div>
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 centered-content round-corner' 
          style="background-color: rgb(32, 56, 10);">
          <h3 class="pt-3" style="color: rgb(92, 216, 0);">1,100,000</h3>
          <br>
          <h4>Energica Software</h4>
        </div>
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 centered-content round-corner'>
          <h3 class="pt-3">1,500,000</h3>
          <br>
          <h4>F22 Raptor</h4>
        </div>
        <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 centered-content round-corner'>
          <h3 class="pt-3">1,800,000</h3>
          <br>
          <h4>Hubble Telescope</h4>
        </div>
      </div>
    </div>
  </section>

  <section id="main-video">

  </section>

  <section id="carousel">
    <div id="videoCarousel" class="carousel slide" data-bs-touch="true">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <iframe src="https://player.vimeo.com/video/336093691?h=0bc68fe555&portrait=0" width="100%" height="800" 
              frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
        <div class="carousel-item">
          <iframe src="https://player.vimeo.com/video/337487257?h=8c1b29eacc&portrait=0" width="100%" height="800"  
              frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
        <div class="carousel-item">
          <iframe src="https://player.vimeo.com/video/337490507?h=2092286154" width="100%" height="800"  
              frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
        <div class="carousel-item">
          <iframe src="https://player.vimeo.com/video/336094948?h=b3847fdea7" width="100%" height="800" 
              frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#videoCarousel" data-bs-slide="prev" style="height: 80% !important;">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#videoCarousel" data-bs-slide="next" style="height: 80% !important;">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </section>
</template>


<style scoped>
  iframe {
    border-radius: 25px;
  }

  img {
    width: 100%;
  }

  .round-corner {
    border-radius: 15px;
  }

</style>
<script setup>
import { onMounted, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { useRouter, useRoute } from 'vue-router'
let router = useRouter()
let route = useRoute()
const toast = useToast()
const store = useStore()

onMounted(async () => {
})
</script>