<template>
    <div id="info">
        <h1>Importer &amp; Distributor</h1>
        <p>Electronia signed an Import &amp; Distribution Agreement with Energica in June 2019, 
            and we are committed to distribute the entire range of Energica products, including 
            spare parts, merchandising and marketing material.&nbsp;To this end, we have undergone 
            technical training and participated in the first ever MyElectric Acadamy Track Day 
            hosted by Energica.
        </p>
        <p>
            We will be looking at appointing Dealerships, and if you are interested to participate 
            in this new, disruptive industry, be sure to 
            <a href="https://www.e2u.co.za/contact/" target="_blank" rel="noopener noreferrer">Contact</a> us.
        </p>
    </div>

    <section id="images">
        <div class='row'>
            <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
                <img src="./FvV-First-Ever-Electric-Bike-Track-Day-Academy.jpg">
            </div>
            <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
                <img src="./JJvR-First-Ever-Electric-Bike-Track-Day-Academy.jpg">
            </div>
            <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
                <img src="./EGO-Leather-Seat-Ohlins-Gold-trim-1.jpg">
            </div>
            <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
                <img src="./FvV-Tech-Training.jpg">
            </div>
            <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
                <img src="./JJvR-Tech-Training.jpg">
            </div>
            <div class='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4'>
                <img src="./EVA-Ohlins-Gold-trim-1.jpg" style="object-fit: cover;">
            </div>
        </div>
    </section>
</template>


<style scoped>
    img:hover {
        transform: scale(1.5);
    }
</style>