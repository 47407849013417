import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home/HomeView.vue'
import About from '../views/About/About.vue'
import AboutElectronia from '../views/About/AboutElectronia.vue'
import Technology from '../views/Tech/Technology.vue'
import Order from '../views/Order/Order.vue'
import Gallery from '../views/Gallery/Gallery.vue'
import News from '../views/News/News.vue'

import energicaegobllysbikes from '../views/News/Blogs/1/energica-ego-bllys-bikes.vue'
import interviewwithrodgerdstvaudiochannel869 from '../views/News/Blogs/2/interview-with-rodger-dstv-audio-channel-869.vue'
import firstenergicareviewsouthafricathebikeshow from '../views/News/Blogs/3/first-energica-review-south-africa-the-bike-show.vue'
import motogptheelectriceraisgofacebook from '../views/News/Blogs/4/motogp-the-electric-era-is-go-facebook.vue'
import fimmotoeworldcupmotogp from '../views/News/Blogs/5/fim-motoe-world-cup-motogp.vue'
import FIMEnelMotoEWorldCup from '../views/News/Blogs/6/FIM+Enel+MotoE+World+Cup.vue'

import Contact from '../views/Contact/Contact.vue'

const routes = [
  {path: '/', name: 'home', component: HomeView, meta: { title: 'Home' }},
  {path: '/about', name: 'about', component: About, meta: { title: 'About' }},
  {path: '/about/electronia', name: 'aboutElectronia', component: AboutElectronia, meta: { title: 'About Electronia' }},
  {path: '/technology', name: 'technology', component: Technology, meta: { title: 'Technology' }},
  {path: '/models-2', name: 'order', component: Order, meta: { title: 'Order' }},
  {path: '/gallery', name: 'gallery', component: Gallery, meta: { title: 'Gallery' }},
  {path: '/blog', name: 'news', component: News, meta: { title: 'News' }},
  {path: '/blog/energica-ego-bllys-bikes', name: 'energicaegobllysbikes', component: energicaegobllysbikes, meta: { title: 'Energica Ego – Billy’s Bikes' }},
  {path: '/blog/interview-with-rodger-dstv-audio-channel-869', name: 'interviewwithrodgerdstvaudiochannel869', component: interviewwithrodgerdstvaudiochannel869, meta: { title: 'Interview with Rodger – DSTV Audio channel 869' }},
  {path: '/blog/first-energica-review-south-africa-the-bike-show', name: 'firstenergicareviewsouthafricathebikeshow', component: firstenergicareviewsouthafricathebikeshow, meta: { title: 'First Energica Review South Africa – The Bike Show' }},
  {path: '/blog/motogp-the-electric-era-is-go-facebook', name: 'motogptheelectriceraisgofacebook', component: motogptheelectriceraisgofacebook, meta: { title: 'MotoGP – The electric era is GO! | Facebook' }},
  {path: '/blog/fim-motoe-world-cup-motogp', name: 'fimmotoeworldcupmotogp', component: fimmotoeworldcupmotogp, meta: { title: 'FIM MotoE™ World Cup | MotoGP™' }},
  {path: '/blog/FIM+Enel+MotoE+World+Cup', name: 'FIMEnelMotoEWorldCup', component: FIMEnelMotoEWorldCup, meta: { title: 'Discover the Energica Ego Corsa MotoE™ motorcycle | MotoGP™' }},
  {path: '/contact', name: 'contact', component: Contact, meta: { title: 'Contact' }},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  document.title = `${to.meta.title} | E2U`;
  return;
})

export default router
