<template>
  <nav class="navbar navbar-expand-lg navbar-light sticky-top" style="background-color: rgb(255, 255, 255); height: 80px;">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <router-link :to="{ name: 'home' }" class="navbar-brand">
        <img style="width: 150px !important;" src="./brand.png">
      </router-link>

      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <div class="d-flex ms-auto">
          <ul class="navbar-nav">

              <ul class="nav navbar-nav mb-2 mb-lg-0">
                <li class="nav-item">
                  <router-link class="nav-link active" :to="{name: 'about'}">About</router-link>
                </li>
              </ul>
              <ul class="nav navbar-nav mb-2 mb-lg-0">
                <li class="nav-item">
                  <router-link class="nav-link active" :to="{name: 'technology'}">Technology</router-link>
                </li>
              </ul>
              <ul class="nav navbar-nav mb-2 mb-lg-0">
                <li class="nav-item">
                  <router-link class="nav-link active" :to="{name: 'order'}">Order</router-link>
                </li>
              </ul>
              <ul class="nav navbar-nav mb-2 mb-lg-0">
                <li class="nav-item">
                  <router-link class="nav-link active" :to="{name: 'gallery'}">Gallery</router-link>
                </li>
              </ul>
              <ul class="nav navbar-nav mb-2 mb-lg-0">
                <li class="nav-item">
                  <router-link class="nav-link active" :to="{name: 'news'}">News</router-link>
                </li>
              </ul>
              <ul class="nav navbar-nav mb-2 mb-lg-0">
                <li class="nav-item">
                  <router-link class="nav-link active" :to="{name: 'contact'}">Contact</router-link>
                </li>
              </ul>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
  
<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
let router = useRouter()
const store = useStore()

function logout() {
  if (confirm('Do you really want to log out?')) {
    const payload = {
      api: '/auth/token/logout/',
      data: {}
    }
    store.dispatch('post', payload)
    store.commit('removeTokens')
    router.push({ name: 'home' })
  }
}
</script>
  
<style type="text/css">
a:hover {
  cursor: pointer;
}

@media all {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .nav-link {
    color: rgb(190, 189, 189) ;
  }

  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }

  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }

  .nav-link {
    color:rgb(109, 109, 109) !important;
    font-weight: bold !important;
  }
}
</style>
  