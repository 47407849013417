<template>
    <div class='row'>
        <div class='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
            <router-link :to="{name: 'aboutElectronia'}">
                <img src="./Electronia_800x800.png">
            </router-link>
        </div>
        <div class='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
            <a href="https://www.energicamotor.com/about-us/" target="_blank">
                <img src="./Energica_logo_05_800x800.png">
            </a>
        </div>
    </div>
</template>