<template>
    <div style="background-color: white; color: black;" class="px-5 mx-5">
        <h3>
            FIM MotoE™ World Cup | MotoGP™        </h3>
        <h6>
            by Gerhard | Jul 7, 2019 | Energica
        </h6>

        <hr>

        <div class="pb-3">
            <img src="./header.png">
        </div>

        <a href="http://www.motogp.com/en/FIM+Enel+MotoE+World+Cup">FIM MotoE™ World Cup | MotoGP™</a>

    </div>
</template>